/* GumrukHesaplama.css */
.hesaplama-container.gumruk {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .checkbox-grubu {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .checkbox-grubu input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: #4CAF50;
  }
  
  .vergi-detaylari {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    margin: 2rem 0;
  }
  
  .vergi-satir {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 8px 0;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .vergi-satir.toplam {
    border-bottom: 2px solid #2d3748;
  }
  
  .kirmizi {
    color: #f44336;
    font-weight: 600;
  }
  .temizle-btn {
    background: #f44336;
    color: white;
  }
  
  .temizle-btn:hover {
    background: #d32f2f;
    transform: translateY(-2px);
  }
  .yesil {
    color: #4CAF50;
    font-weight: 600;
  }
  
  .vergi-tablosu table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5rem;
  }
  
  .vergi-tablosu th {
    background: #2196F3;
    color: white;
    padding: 12px;
    text-align: left;
  }
  
  .vergi-tablosu td {
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  @media (max-width: 768px) {
    .vergi-satir {
      flex-direction: column;
      gap: 4px;
    }
    
    .vergi-tablosu table {
      font-size: 14px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */