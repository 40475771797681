.konut-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
}

.konut-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
    font-family:'Poppins' ;
}

.konut-form label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
}

.konut-form input {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.hesapla-button, .temizle-button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
}

.hesapla-button {
    background-color: #4CAF50;
    color: white;
}

.hesapla-button:hover {
    background-color: #45a049;
}

.temizle-button {
    background-color: #f44336;
    color: white;
}

.temizle-button:hover {
    background-color: #d32f2f;
}

.error-message {
    color: red;
    margin-top: 10px;
    font-weight: bold;
}

.sonuc-card {
    margin-top: 30px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    text-align: center;
}

.sonuc-card h2 {
    margin-bottom: 20px;
    font-size: 26px;
    color: #333;
}

.sonuc-card p {
    font-size: 18px;
    margin: 10px 0;
}

.chart-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
