/* GunHesaplama.css */
.hesaplama-container.gun-hesaplama {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .gun-sonuc {
    text-align: center;
    margin: 2rem 0;
    font-size: 48px;
    color: #2d3748;
  }
  
  .gun-sonuc .sayi {
    font-weight: 700;
    color: #4CAF50;
  }
  
  .gun-sonuc .metin {
    color: #718096;
  }
  
  .aciklama {
    text-align: center;
    color: #718096;
    margin-top: 1rem;
    font-size: 14px;
  }
  
  .aksiyon-bolum {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .temizle-btn {
    background: #2196F3;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .temizle-btn:hover {
    background: #1976D2;
    transform: translateY(-2px);
  }
  
  /* Mobil Uyum */
  @media (max-width: 768px) {
    .gun-sonuc {
      font-size: 36px;
    }
    
    .temizle-btn {
      width: 100%;
      padding: 14px;
    }
  }