/* Ana container */
.ales-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 30px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 6px 18px rgba(0,0,0,0.1);
    font-family: 'Poppins', sans-serif;
}

/* Başlık */
.ales-title {
    font-size: 36px;
    margin-bottom: 25px;
    color: #333;
    font-weight: 700;
    text-align: center;
}

/* Form içi etiketler ve inputlar */
.ales-form label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
}

.ales-form input {
    width: 100%;
    padding: 14px;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.ales-form input:focus {
    outline: none;
    border-color: #4CAF50;
}

/* Form bölümleri */
.ales-section {
    margin-bottom: 30px;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 12px;
    box-shadow: inset 0 2px 6px rgba(0,0,0,0.05);
}

/* Buton grubu */
.button-group {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.hesapla-button, .temizle-button {
    padding: 14px 30px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.hesapla-button {
    background-color: #4CAF50;
    color: white;
}

.hesapla-button:hover {
    background-color: #45a049;
    transform: scale(1.02);
}

.temizle-button {
    background-color: #f44336;
    color: white;
}

.temizle-button:hover {
    background-color: #d32f2f;
    transform: scale(1.02);
}

/* Hata mesajı */
.error-message {
    color: red;
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
}

/* Sonuç kartı */
.sonuc-card {
    margin-top: 30px;
    padding: 25px;
    background: linear-gradient(145deg, #f0f0f0, #ffffff);
    border-radius: 16px;
    box-shadow: 0 6px 18px rgba(0,0,0,0.1);
    text-align: center;
}

.sonuc-card h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
}

.sonuc-card p {
    font-size: 20px;
    margin: 12px 0;
    font-weight: 600;
}

/* Grafikler için */
.chart-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

/* Progress Bar - Puan göstergesi */
.progress-bar {
    width: 100%;
    background: #eee;
    border-radius: 50px;
    overflow: hidden;
    margin: 15px 0;
    height: 24px;
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
}

.progress-fill {
    height: 100%;
    line-height: 24px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px;
    transition: width 0.5s ease-in-out;
}

/* Sayısal ve Sözel renkler */
.progress-sayisal {
    background: #4CAF50;
}

.progress-sozel {
    background: #2196F3;
}

/* Genel ALES puanı */
.progress-ales {
    background: #9C27B0;
}
