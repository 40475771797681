.ehliyet-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .ehliyet-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
    font-family:'Poppins' ;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    font-size: 16px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 550;
    text-align: center;
    font-family:'Poppins' ;
  }
  
  form input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
  }
  
  button {
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .hesapla-button {
    background-color: #4CAF50;
    color: white;
    font-family: 'Poppins';
  }
  
  .hesapla-button:hover {
    background-color: #45a049;
  }
  
  .temizle-button {
    background-color: #f44336;
    color: white;
    font-family: 'Poppins';
  }
  
  .temizle-button:hover {
    background-color: #d32f2f;
  }
  
  .sonuc-container {
    margin-top: 20px;
  }
  
  .progress-bar {
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .progress-fill {
    height: 100%;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .progress-fill.pass {
    background-color: green;
  }
  
  .progress-fill.fail {
    background-color: red;
  }
  