/* Temel Stiller */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.lgs-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0,0,0,0.08);
}

.lgs-header {
  text-align: center;
  margin-bottom: 2rem;
}

.lgs-header h1 {
  font-size: 36px;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.lgs-header p {
  color: #718096;
  font-size: 16px;
}

/* Ders Grid */
.ders-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.ders-karti {
  background: #f8fafc;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.ders-baslik {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.ders-baslik h3 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.ders-baslik span {
  font-size: 12px;
  color: #718096;
  background: #edf2f7;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.input-grubu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.input-grubu input {
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s;
}

.input-grubu input:focus {
  outline: none;
  border-color: #48bb78;
  box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.1);
}

/* Butonlar */
.button-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.hesapla-btn {
  background:  #4CAF50;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}

.hesapla-btn:hover {
  background:  #4CAF50;
  transform: translateY(-1px);
}

.temizle-btn {
  background:  #f44336;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}

.temizle-btn:hover {
  background:  #f44336;
}

/* Sonuç Paneli */
.sonuc-paneli {
  margin-top: 2rem;
  padding: 2rem;
  background: #f8fafc;
  border-radius: 12px;
}

.sonuc-paneli h2 {
  font-size: 20px;
  color: #2d3748;
  margin-bottom: 1.5rem;
}

.net-listesi {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.net-item {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.net-deger {
  font-size: 16px;
  font-weight: 600;
  color: #48bb78;
  margin-top: 0.5rem;
}

.toplam-puan {
  text-align: center;
  padding: 1.5rem;
  background: linear-gradient(135deg, #48bb78, #38a169);
  border-radius: 12px;
  color: white;
}

.puan-gosterge {
  font-size: 32px;
  font-weight: 700;
  margin-top: 1rem;
}

/* Hata Mesajı */
.hata-mesaji {
  color: #c53030;
  background: #fff5f5;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
  border: 1px solid #fed7d7;
}

/* Responsive */
@media (max-width: 768px) {
  .ders-grid {
    grid-template-columns: 1fr;
  }

  .net-listesi {
    grid-template-columns: repeat(2, 1fr);
  }

  .lgs-container {
    margin: 1rem;
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .net-listesi {
    grid-template-columns: 1fr;
  }

  .input-grubu {
    grid-template-columns: 1fr;
  }

  .button-container {
    flex-direction: column;
  }

  .hesapla-btn, 
  .temizle-btn {
    width: 100%;
  }
}