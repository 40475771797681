/* FaktoriyelHesaplama.css */
.hesaplama-container.faktoriyel {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .sonuc-metin {
    background: #f8fafc;
    padding: 2rem;
    border-radius: 12px;
    margin-top: 1.5rem;
    word-wrap: break-word;
  }
  
  .sonuc-ust {
    display: flex;
    align-items: baseline;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .sonuc-sayi {
    font-size: 24px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .sonuc-deger {
    font-size: 18px;
    color: #718096;
    line-height: 1.6;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .karakter-sayisi {
    color: #4CAF50;
    font-weight: 500;
    text-align: right;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .sonuc-deger {
      font-size: 14px;
      max-height: 300px;
    }
    
    .sonuc-sayi {
      font-size: 20px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */