/* AlanHesaplama.css */
.hesaplama-container.alan {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .alan-sonuc {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 2rem 0;
    gap: 8px;
  }
  
  .alan-deger {
    font-size: 48px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
  }
  
  .alan-birim {
    font-size: 24px;
    color: #718096;
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */
  
  /* Mobil Uyum */
  @media (max-width: 768px) {
    .alan-deger {
      font-size: 36px;
    }
    
    .alan-birim {
      font-size: 18px;
    }
  }