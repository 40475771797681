/* TaksiHesaplama.css */
.taksi-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    font-family: 'Segoe UI', sans-serif;
  }
  
  .baslik {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .input-grid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 1rem;
    display: block;

  }
  
  
  .il-select {
    width: 100%;
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    background: #f8fafc;
  }
  
  input {
    width: 100%;
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .hesapla-btn {
    width: 100%;
    padding: 15px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .hesapla-btn:hover {
    background: #45a049;
  }
  
  .sonuc-paneli {
    margin-top: 2rem;
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
  }
  
  .ucret-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
  }
  
  .ucret {
    font-size: 24px;
    font-weight: 700;
    color: #2196F3;
  }
  
  .tarife-bilgisi {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .tarife-bilgisi ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .tarife-bilgisi li {
    margin: 0.5rem 0;
    color: #718096;
  }
  
  .bilgilendirme {
    margin-top: 2rem;
    color: #718096;
    font-size: 14px;
    text-align: center;
  }
  
  .hata-mesaji {
    color: #f44336;
    text-align: center;
    margin: 1rem 0;
  }
  
  @media (max-width: 768px) {
    .taksi-container {
      margin: 1rem;
      padding: 1.5rem;
    }
    
    .ucret {
      font-size: 20px;
    }
  }