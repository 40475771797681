/* KDVHesaplama.css */
.hesaplama-container.kdv {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .sonuc-grid {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    margin: 2rem 0;
  }
  
  .sonuc-satir {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 8px 0;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .sonuc-satir span:first-child {
    color: #718096;
  }
  
  .sonuc-satir span:last-child {
    font-weight: 600;
    color: #4CAF50;
  }
  
  @media (max-width: 768px) {
    .sonuc-satir {
      flex-direction: column;
      gap: 4px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */