/* UsluHesaplama.css */
.hesaplama-container.uslu {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .uslu-sonuc {
    font-size: 24px;
    text-align: center;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .taban {
    font-size: 32px;
    font-weight: 600;
    color: #2d3748;
  }
  
  .us {
    font-size: 0.8em;
    vertical-align: super;
    color: #718096;
    margin-left: 2px;
  }
  
  .sonuc-deger {
    font-size: 32px;
    font-weight: 700;
    color: #4CAF50;
    margin-left: 10px;
  }
  
  @media (max-width: 768px) {
    .uslu-sonuc {
      flex-direction: column;
      gap: 8px;
    }
    
    .sonuc-deger {
      font-size: 24px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */