/* HacimHesaplama.css */
.hesaplama-container.hacim {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .hacim-sonuc {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 2rem 0;
    gap: 8px;
  }
  
  .hacim-deger {
    font-size: 48px;
    font-weight: 700;
    color: #2196F3;
    line-height: 1;
  }
  
  .hacim-birim {
    font-size: 24px;
    color: #718096;
  }
  
  @media (max-width: 768px) {
    .hacim-deger {
      font-size: 36px;
    }
    
    .hacim-birim {
      font-size: 18px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */