/* Navbar için stil */  
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #4CAF50; /* Eski yeşil renk */
    color: white;
    position: relative;
    z-index: 1;
    box-shadow: 4px 2px 0px #ff8800;

}

.logo {
    font-family: 'Poppins', cursive;
    font-size: 40px;
    margin: 0;
    font-weight: bold;
    font-style: italic;
    transition: font-size 0.3s ease;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* X, Y kaydırma ve bulanıklık */

}

.navLinks {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.navLinks > div {
    margin-right: 25px;
    position: relative;
}

/* Ana menü linkleri - Kategoriler için daha kalın yazı tipi */
.navLinks a {
    text-decoration: none;
    background-color: #4CAF50; /* Eski yeşil renk */
    color: #ffffff;
    font-size: 18px;
    font-family:'Poppins' ;
    font-weight: bold; /* Normal yazı tipi */
    padding: 10px;
    display: block;
    position: relative;
}

/* Ana menü başlıkları (kategoriler) bold olacak */
.navLinks > div > a.main-link {
    font-weight: bold; /* Kategoriler için bold yazı tipi */
}

/* Dropdown Menü */
.navLinks .dropdown {
    background-color: #FF7043; /* Alt menü için turuncu */
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    padding: 10px 0;
    min-width: 250px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

/* 'open' sınıfı ile dropdown'ı göster */
.navLinks > div.open .dropdown {
    display: block;
}

/* Ok simgesi için dönüş efekti */
.navLinks > div a .arrow {
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.navLinks > div.open a .arrow {
    transform: rotate(180deg);
}

/* Alt Menü (SubDropdown) */
.subDropdown {
    background-color: #FF7043; /* Alt menü için turuncu */
    padding-left: 10px;
    border-radius: 5px;
    font-weight: normal
}

.subDropdown a {
    color: #ffffff;
    padding: 8px 10px;
    display: block;
    border-bottom: 1px solid #ff5722;
    font-weight: normal /* Menü öğeleri arasına turuncu çizgi ekleyelim */
}

/* Son öğenin altına çizgi eklenmemesi için son öğe seçicisi */
.subDropdown a:last-child {
    border-bottom: none;
}

/* Menüdeki öğelerin hover efekti */
.navLinks > div a:hover,
.subDropdown a:hover {
    background-color: #FF5722; /* Hoverda kırmızımsı turuncu ton */
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

body {
    background-color: #ffffff;
background-image: url("https://www.transparenttextures.com/patterns/60-lines.png");
    
    
}

