/* IdealKiloHesaplama.css */
.hesaplama-container.ideal-kilo {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .kilo-aralik {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
  }
  
  .kilo-kutu {
    background: #f8fafc;
    padding: 1.5rem 2rem;
    border-radius: 12px;
    text-align: center;
    min-width: 120px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  
  .kilo-deger {
    font-size: 36px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
  }
  
  .kilo-birim {
    color: #718096;
    font-size: 14px;
    margin-top: 0.5rem;
  }
  
  .aralik-ok {
    font-size: 24px;
    color: #718096;
    margin-top: -1rem;
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */