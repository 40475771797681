/* BmiHesaplama.css */
.hesaplama-container.bmi {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .bmi-sonuc {
    margin: 2rem 0;
    text-align: center;
  }
  .input-gridd {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1rem 0;
    display: flex;
    
  }
  
  .bmi-deger {
    font-size: 64px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
  }
  .input-grubuu {
    margin: 2rem 0;
    display: flex;
    justify-content: center;

  }
  .bmi-kategori {
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    margin-top: 1rem;
  }
  
  .bmi-tablosu {
    width: 100%;
    margin-top: 2rem;
    border-top: 1px solid #e2e8f0;
    padding-top: 1.5rem;
  }
  
  .bmi-aralik {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    padding: 10px;
    border-radius: 8px;
    background: #f8fafc;
  }
  
  .bmi-aralik span:first-child {
    font-weight: 500;
    color: #2d3748;
  }
  
  .bmi-aralik span:nth-child(2) {
    color: #718096;
    margin: 0 1rem;
  }
  
  .renk-kutu {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }
  
  /* Diğer stiller KaloriHesaplama.css ile aynı */