/* Hesaplama.css'ye ekle */body {
  font-family: 'Poppins', sans-serif;
  color: #2d3748;
  text-align: center;
  
}
.vize-final .hesaplama-container {
    max-width: 600px;
    
  }
  
  .vize-sayisi-secim {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .vize-sayisi-btn {
    padding: 8px 20px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: #f8fafc;
    cursor: pointer;
    transition: all 0.2s;
    color: #000;
  }
  
  .vize-sayisi-btn.active {
    background: #4CAF50;
    color: white;
    border-color: #4CAF50;
  }
  
  .not-grid {
    display: grid;
    gap: 1rem;
  }
  
  .not-grubu {
    background: #f8fafc;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .not-grubu h3 {
    margin: 0 0 1rem 0;
    font-size: 15px;
    color: #2d3748;
  }
  
  .not-grubu input {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 8px 12px;
  }
  
  .not-grubu.final {
    background: #fff7ed;
    border-color: #fed7aa;
  }
  
  .ortalama-sonuc {
    font-size: 32px;
    font-weight: 700;
    color: #4CAF50;
    text-align: center;
    margin: 1rem 0;
  }
  
  .sonuc-aciklama {
    text-align: center;
    font-size: 18px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .vize-sayisi-secim {
      flex-wrap: wrap;
    }
    
    .vize-sayisi-btn {
      flex: 1;
      text-align: center;
    }
  }