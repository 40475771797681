/* MesafeHesaplama.css */
.mesafe-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    font-family: 'Segoe UI', sans-serif;
  }
  
  h1 {
    color: #2d3748;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .input-grubu {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  input {
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  input:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  button {
    width: 100%;
    padding: 15px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button:disabled {
    background: #a5d6a7;
    cursor: not-allowed;
  }
  
  button:hover:not(:disabled) {
    background: #45a049;
  }
  
  .sonuc-kutusu {
    margin-top: 2rem;
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 10px;
  }
  
  .sonuc-item {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 18px;
    padding: 8px 0;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .vurgu {
    font-weight: 600;
    color: #2196F3;
  }
  
  .bilgilendirme {
    margin-top: 2rem;
    color: #718096;
    font-size: 14px;
    text-align: center;
  }
  
  .hata {
    color: #f44336;
    text-align: center;
    margin: 1rem 0;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .mesafe-container {
      margin: 1rem;
      padding: 1.5rem;
    }
    
    .sonuc-item {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }