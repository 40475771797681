/* YakitHesaplama.css */
.yakit-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    font-family: 'Segoe UI', sans-serif;
  }
  
  .baslik {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .mod-secimi {
    display: grid;
    gap: 1rem;
    margin-bottom: 2rem;
    
  }
  
  .mod-secimi button {
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    background: #f8fafc;
    cursor: pointer;
    transition: all 0.3s;
    color: #000000

  }
  
  .mod-secimi button.aktif {
    border-color: #4CAF50;
    background: #e8f5e9;
  }
  
  .input-grid {
    display: grid;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .input-grubu {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  input {
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .hesapla-btn {
    width: 100%;
    padding: 15px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .hesapla-btn:hover {
    background: #45a049;
  }
  
  .sonuc {
    margin-top: 2rem;
    text-align: center;
  }
  
  .sonuc-deger {
    font-size: 24px;
    color: #2196F3;
    font-weight: 700;
    margin: 1rem 0;
  }
  
  .bilgilendirme {
    margin-top: 2rem;
    color: #718096;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 8px;
  }
  
  .hata {
    color: #f44336;
    text-align: center;
    margin: 1rem 0;
  }
  
  @media (max-width: 768px) {
    .yakit-container {
      margin: 1rem;
      padding: 1.5rem;
    }
    
    .sonuc-deger {
      font-size: 20px;
    }
  }