/* AskerlikHesaplama.css */
.hesaplama-container.askerlik {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .safak-sonuc {
    text-align: center;
    margin: 2rem 0;
  }
  
  .safak-sonuc .gun {
    font-size: 72px;
    font-weight: 700;
    color: #2196F3;
    line-height: 1;
  }
  
  .safak-sonuc .birim {
    font-size: 24px;
    color: #718096;
    margin-left: 10px;
  }
  
  @media (max-width: 768px) {
    .safak-sonuc .gun {
      font-size: 48px;
    }
    
    .safak-sonuc .birim {
      font-size: 18px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */