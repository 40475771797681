/* Hesaplama.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

.hesaplama-container.takdir {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.08);
  text-align: center;
  font-family: 'Poppins'

}

.baslik-bolumi {
  text-align: center;
  margin-bottom: 2rem;
  
}

.baslik-bolumi h1 {
  font-size: 26px;
  color: #333;
  margin-bottom: 0.5rem;
  text-align: center;
  

}

.baslik-bolumi p {
  color: #718096;
  font-size: 14px;
}

.ders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.ders-karti {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e2e8f0;
}

.ders-baslik {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.ders-baslik h3 {
  font-size: 14px;
  margin: 0;
  color: #1a202c;
  
}

.saat {
  font-size: 12px;
  color: #718096;
  background: #edf2f7;
  padding: 2px 8px;
  border-radius: 4px;
}

.not-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;
}

.not-input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76,175,80,0.1);
}

.aksiyon-bolum {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.hesapla-btn {
  background: #4CAF50;
  color: white;
  padding: 0.6rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s;
}

.hesapla-btn:hover {
  transform: translateY(-1px);
  background: #45a049;
}

.temizle-btn {
  background-color: #f44336;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.temizle-btn:hover {
    background-color: #d32f2f;
}

.sonuc-paneli {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8fafc;
  border-left: 4px solid;
  border-radius: 8px;
}

.ortalama {
  font-size: 18px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.belge {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.hata-mesaji {
  color: #c53030;
  background: #fff5f5;
  padding: 0.8rem;
  border-radius: 6px;
  margin: 1rem 0;
  text-align: center;
}

@media (max-width: 768px) {
  .hesaplama-container.takdir {
    margin: 1rem;
    padding: 1rem;
  }
  
  .ders-grid {
    grid-template-columns: 1fr;
  }



}