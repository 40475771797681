/* GeriSayimHesaplama.css */
.hesaplama-container.geri-sayim {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .zaman-grid-detay,
  .zaman-grid-anlik {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1.5rem 0;
  }
  
  .zaman-grid-anlik .zaman-kutu {
    background: #e3f2fd;
  }
  
  .zaman-kutu {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .zaman-deger {
    font-size: 36px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
  }
  
  .zaman-birim {
    color: #718096;
    margin-top: 0.5rem;
  }
  
  .bitis-mesaji {
    text-align: center;
    font-size: 24px;
    color: #2196F3;
    padding: 2rem;
  }
  
  @media (max-width: 768px) {
    .zaman-grid-detay,
    .zaman-grid-anlik {
      grid-template-columns: 1fr;
    }
    
    .zaman-deger {
      font-size: 28px;
    }
    
    .bitis-mesaji {
      font-size: 20px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */