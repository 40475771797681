.kar-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', sans-serif;
  }
  
  .baslik {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .baslik h1 {
    font-size: 2rem;
    color: #333;
  }
  
  .baslik p {
    font-size: 1rem;
    color: #666;
  }
  
  .inpput-grid {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    justify-content: center;
  }
  
  .inpput-grubu {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .input-grubu label {
    font-size: 1rem;
    color: #333;
  }
  
  .input-grubu input {
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .input-grubu input:focus {
    border-color: #4CAF50;
    outline: none;
  }
  
  .hesapla-btn {
    width: 100%;
    padding: 15px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .hesapla-btn:hover {
    background: #45a049;
  }
  
  .sonuc {
    margin-top: 2rem;
    text-align: center;
  }
  
  .sonuc-deger {
    font-size: 24px;
    color: #2196F3;
    font-weight: 700;
    margin: 1rem 0;
  }
  
  .hata {
    color: #f44336;
    text-align: center;
    margin: 1rem 0;
  }
  
  @media (max-width: 768px) {
    .kar-container {
      margin: 1rem;
      padding: 1.5rem;
    }
  
    .sonuc-deger {
      font-size: 20px;
    }
  }