/* EbobEkokHesaplama.css */
.hesaplama-container.ebob-ekok {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sonuc-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: 2rem 0;
    width: 100%;
  }
  
  .sonuc-kutu {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .sonuc-label {
    color: #718096;
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
  
  .sonuc-deger {
    font-size: 36px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
  }
  
  /* Mobil Uyum */
  @media (max-width: 768px) {
    .sonuc-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    
    .sonuc-deger {
      font-size: 28px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */