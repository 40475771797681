/* KaloriHesaplama.css */
.hesaplama-container.kalori {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .input-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 1rem 0;
  }
  
  @media (max-width: 768px) {
    .input-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .kalori-sonuc {
    margin: 2rem 0;
    text-align: center;
  }
  
  .kalori-deger {
    font-size: 48px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
    margin-right: 8px;
  }
  
  .kalori-birim {
    font-size: 24px;
    color: #718096;
    vertical-align: super;
  }
  
  .uyari {
    color: #718096;
    font-size: 14px;
    margin-top: 1rem;
    text-align: center;
  }
  
  /* Diğer stiller YaşHesaplama.css ile aynı */