/* liseortalamahesaplama.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.hesaplama-container.lise {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0,0,0,0.08);
}

.baslik-bolumu {
  text-align: center;
  margin-bottom: 2rem;
}

.lise-title {
  font-size: 32px;
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.baslik-bolumu p {
  color: #718096;
  font-size: 16px;
}

.ders-sayisi-secimm {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
  justify-content: center;
  
}

.sayi-btn {
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  cursor: pointer;
  transition: all 0.2s;
  color: #000;
}

.sayi-btn.active {
  background: #4CAF50;
  color: white;
  border-color: #45a049;
  transform: scale(1.05);
}

.ders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.ders-karti {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.ders-header h3 {
  font-size: 16px;
  color: #2d3748;
  margin-bottom: 1rem;
}

.ders-select {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  font-size: 14px;
}

.ders-select:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 3px rgba(76,175,80,0.1);
}

.ders-karti input {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
}

.ders-karti input:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 3px rgba(76,175,80,0.1);
}

.aksiyon-bolum {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8fafc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.hesapla-btn {
  background: #4CAF50;
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 160px;
}

.temizle-btn {
  background: #f44336;
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 160px;
}

.hesapla-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(76,175,80,0.15);
}

.temizle-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(244,67,54,0.15);
}

.hata-mesaji {
  background: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  border: 1px solid #ffcdd2;
  text-align: center;
}

.sonuc-paneli {
  margin-top: 2rem;
  padding: 2rem;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
  border-radius: 12px;
  text-align: center;
  border: 1px solid #e2e8f0;
}

.ortalama-deger {
  font-size: 48px;
  font-weight: 700;
  color: #4CAF50;
  margin: 1rem 0;
}

.birim {
  font-size: 24px;
  color: #718096;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .hesaplama-container.lise {
    margin: 1rem;
    padding: 1.5rem;
  }
  
  .ders-grid {
    grid-template-columns: 1fr;
  }
  
  .aksiyon-bolum {
    flex-direction: column;
  }
  
  .hesapla-btn, 
  .temizle-btn {
    width: 100%;
  }
  
  .lise-title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .ders-sayisi-secim {
    justify-content: center;
  }
  
  .sayi-btn {
    flex: 1 0 20%;
    text-align: center;
  }
}