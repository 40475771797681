/* VergiHesaplama.css */
.hesaplama-container.vergi {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .vergi-detay {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    margin: 2rem 0;
  }
  
  .vergi-satir {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 18px;
  }
  
  .vergi-tutar {
    color: #f44336;
    font-weight: 600;
  }
  
  .dilimler {
    margin-top: 2rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }
  
  th {
    background: #4CAF50;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f8fafc;
  }
  
  @media (max-width: 768px) {
    .vergi-satir {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
    
    table {
      font-size: 14px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */