/* YasHesaplama.css */
.hesaplama-container.yas {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  }
  
  .baslik-bolumu {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .baslik-bolumu h1 {
    font-size: 36px;
    color: #2d3748;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  
  .baslik-bolumu p {
    color: #718096;
    font-size: 16px;
  }
  
  .input-grubu {
    margin: 2rem 0;
    
    
  }
  
  .tarih-input {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    background: #f8fafc;
    text-align: center;
    
  }
  
  .tarih-input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 3px rgba(76,175,80,0.1);
    
  }
  
  .aksiyon-bolum {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
    
  }
  
  .hesapla-btn, .temizle-btn {
    padding: 14px 28px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 160px;
  }
  
  .hesapla-btn {
    background: #4CAF50;
    color: white;
  }
  
  .hesapla-btn:hover {
    background: #45a049;
    transform: translateY(-2px);
  }
  
  .temizle-btn {
    background: #f44336;
    color: white;
  }
  
  .temizle-btn:hover {
    background: #d32f2f;
    transform: translateY(-2px);
  }
  
  .sonuc-paneli {
    margin-top: 2rem;
    padding: 2rem;
    background: #f8fafc;
    border-radius: 12px;
    text-align: center;
  }
  
  .yas-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .yas-kutu {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .yas-deger {
    font-size: 42px;
    font-weight: 700;
    color: #4CAF50;
    line-height: 1;
  }
  
  .yas-birim {
    color: #718096;
    margin-top: 0.5rem;
    font-size: 14px;
  }
  
  .hata-mesaji {
    background: #ffebee;
    color: #c62828;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    text-align: center;
    border: 1px solid #ffcdd2;
  }
  
  @media (max-width: 768px) {
    .hesaplama-container.yas {
      margin: 1rem;
      padding: 1.5rem;
    }
    
    .aksiyon-bolum {
      flex-direction: column;
    }
    
    .yas-grid {
      grid-template-columns: 1fr;
    }
    
    .hesapla-btn, 
    .temizle-btn {
      width: 100%;
    }
    
    .yas-deger {
      font-size: 36px;
    }
  }