/* CevreHesaplama.css */
.hesaplama-container.cevre {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0,0,0,0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cevre-sonuc {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 2rem 0;
    gap: 8px;
  }
  
  .cevre-deger {
    font-size: 48px;
    font-weight: 700;
    color: #2196F3;
    line-height: 1;
  }
  
  .cevre-birim {
    font-size: 24px;
    color: #718096;
  }
  
  /* Mobil Uyum */
  @media (max-width: 768px) {
    .hesaplama-container.cevre {
      padding: 1rem;
    }
    
    .input-grid {
      grid-template-columns: 1fr !important;
    }
  
    .cevre-deger {
      font-size: 36px;
    }
    
    .cevre-birim {
      font-size: 18px;
    }
  }
  
  /* Diğer stiller önceki bileşenlerle aynı */