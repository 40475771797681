.button {
  background-color: #4CAF50;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Sabit boyut */
  transition: transform 0.3s, background-color 0.3s;
  font-family: 'Poppins', sans-serif; /* Yazı tipi */
}

.button:hover {
  transform: scale(1.05); /* Büyütme efekti */
  background-color: #66bb6a; /* Renk açma efekti */
}

.icon {
  font-size: 40px;
  margin-bottom: 15px;
}

.label {
  font-size: 18px;
  font-weight: 500;
}

.link {
  text-decoration: none;
}
